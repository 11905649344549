<template>
  <b-container>
    <b-row class="mt-4">
      <b-col>
        <b-btn
          variant="success"
          @click="confirmMatches"
        >
          Confirm matches
        </b-btn>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label for="">Project</label>
        <FormTags
          v-model="project"
          name="project"
          :limit="1"
          button-text="Select project"
          :options="projects"
        >
          <template #item="{option}">
            <b>{{ option.business.name }}</b> - {{ option.title }}
          </template>
        </FormTags>
      </b-col>
    </b-row>
    Role
    <b-form-select
      v-model="selectedRole"
      :options="roleOptions"
    />
    Industry
    <b-form-select
      v-model="selectedIndustry"
      :options="industryOptions"
    />
    Regions
    <b-form-select
      v-model="selectedRegion"
      :options="regionOptions"
    />
    Country
    <b-form-input
      v-model="country"
    />
    Country expertise
    <b-form-select
      v-model="countryExpertise"
      :options="countryOptions"
    />
    <b-row>
      <b-col>
        <label for="">Exclude country</label>
        <FormTags
          v-model="excludeCountry"
          name="exclude_country"
          button-text="Select country"
          :options="countryOptions"
        >
          <template #item="{option}">
            <b>{{ option.text }}</b>
          </template>
        </FormTags>
      </b-col>
    </b-row>
    Native language
    <b-form-select
      v-model="nativeLangugage"
      :options="languageOptions"
    />
    Languages
    <b-form-select
      v-model="languages"
      :options="languageOptions"
    />
    <b-row>
      <b-col>
        <label for="">Recruiter status</label>
        <FormTags
          v-model="status"
          name="recruiter_status"
          button-text="Select status"
          :options="statuses"
        >
          <template #item="{option}">
            <b>{{ option.text }}</b>
          </template>
        </FormTags>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label for="">Exclude Recruiter status</label>
        <FormTags
          v-model="excludeRecStatus"
          name="exclude_status"
          button-text="Select status"
          :options="statuses"
        >
          <template #item="{option}">
            <b>{{ option.text }}</b>
          </template>
        </FormTags>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label for="">Recruiter's availability</label>
        <FormTags
          v-model="availability"
          name="availability"
          button-text="Select availability"
          :options="availabilityOptions"
        >
          <template #item="{option}">
            <b>{{ option.text }}</b>
          </template>
        </FormTags>
      </b-col>
    </b-row>
    <span class="ml-2 mt-2">
      <b>Counter:</b> {{ filteredRecruiters.length }}
    </span>
    <b-form-checkbox-group v-model="selectedRecruiters">
      <b-card
        v-for="recruiter in filteredRecruiters"
        :key="recruiter.id"
        class="mb-4"
      >
        <b-form-checkbox :value="recruiter.id">
          Select for match
        </b-form-checkbox>

        <ProfileCard
          :match="{
            recruiter,
            project: {}
          }"
        />
        <div>
          <b-btn
            class="mr-5"
            variant="success"
            :to="`/admin/recruiter/${recruiter.id}/edit`"
          >
            Edit
          </b-btn>
          <span class="ml-2">
            <b-btn
              class="mr-2"
              @click="vetting(recruiter)"
            >
              Vetting
            </b-btn>
            <b>Rec status:</b>
            <b-form-select
              v-model="recruiter.status"
              :options="recruiterStatuses"
              size="sm"
              style="width: fit-content;"
            >
              <template #first>
                <b-form-select-option :value="recruiter.status">
                  {{ recruiter.status }}
                </b-form-select-option>
              </template>
            </b-form-select>
            |
            <b>Rec availability:</b> {{ recruiter.availability }}
            <b-btn
              class="ml-1 p-0"
              variant="admin-primary"
              @click="saveRecruiterInfo(recruiter)"
            >
              Save
            </b-btn>
          </span>
          <b-btn
            variant="danger"
            class="float-right"
            @click="remove(recruiter.id)"
          >
            Delete
          </b-btn>
          <p>{{ recruiter.user.email }}</p>
        </div>
      </b-card>
    </b-form-checkbox-group>
  </b-container>
</template>

<script>
import axios from '@/helpers/axios'
import ProfileCard from '@/components/match/ProfileCard'
import FormTags from '@/components/FormTags'
import { RECRUITER_STATUSES, statusToArray } from '@/helpers/statuses'

export default {
  name: 'RecruiterList',
  components: {
    ProfileCard,
    FormTags,
  },
  data () {
    return {
      recruiters: [],
      selectedRecruiters: [],
      project: null,
      projects: [],
      // Filters
      roleOptions: [],
      selectedRole: null,
      industryOptions: [],
      country: null,
      regionOptions: [],
      selectedRegion: null,
      selectedIndustry: null,
      nativeLangugage: null,
      languages: null,
      languageOptions: [],
      // vetted: false,
      status: [],
      excludeRecStatus: [],
      availability: [],
      countryExpertise: [],
      excludeCountry: [],
      statuses: statusToArray(RECRUITER_STATUSES),
      availabilityOptions: [
        { text: 'Not available', value: 'Not available' },
        { text: 'Available', value: 'Available' },
        { text: 'Available from January', value: 'Available from January' },
        { text: 'Available from February', value: 'Available from February' },
        { text: 'Available from March', value: 'Available from March' },
        { text: 'Available from April', value: 'Available from April' },
        { text: 'Available from May', value: 'Available from May' },
        { text: 'Available from June', value: 'Available from June' },
        { text: 'Available from July', value: 'Available from July' },
        { text: 'Available from August', value: 'Available from August' },
        { text: 'Available from September', value: 'Available from September' },
        { text: 'Available from October', value: 'Available from October' },
        { text: 'Available from November', value: 'Available from November' },
        { text: 'Available from December', value: 'Available from December' },
      ],
      countryOptions: [],
      recruiterStatuses: [
        'Email verification',
        'Finish profile',
        'Profile review',
        'Vetting',
        'Active',
        'Passive',
        'Needs quality validation',
        'Maybe later',
        'In-house opportunities',
        'Bad experience',
        'Lost',
        'Out of scope',
      ],
      availabilities: [
        'Available',
        'Not available ',
        'Available from January',
        'Available from February',
        'Available from March',
        'Available from April',
        'Available from May',
        'Available from June',
        'Available from July',
        'Available from August',
        'Available from September',
        'Available from October',
        'Available from November',
        'Available from December',
      ],
    }
  },
  computed: {
    filteredRecruiters () {
      let recruiters = this.recruiters
      const role = (this.selectedRole || '').toLowerCase()
      const industry = (this.selectedIndustry || '').toLowerCase()
      const country = (this.country || '').toLowerCase()
      const region = (this.selectedRegion || '').toLowerCase()
      const nativeLangugage = this.nativeLangugage
      const languages = this.languages
      const status = this.status.map(status => (status || '').toLowerCase())
      const availability = this.availability.map(availability => (availability || '').toLowerCase())
      const excludeCountry = this.excludeCountry
      const countryExpertise = this.countryExpertise
      const excludeRecStatus = this.excludeRecStatus

      // Apply selectedRole filter
      if (role) {
        recruiters = recruiters.filter(r => r.roles.find(r => r.id === role))
      }
      // Apply selectedIndustry filter
      if (industry) {
        recruiters = recruiters.filter(r => r.industries.find(r => r.id === industry))
      }
      // Start of country fiter
      if (country) {
        recruiters = recruiters.filter(r => ((r.country || {}).label || '').toLowerCase().includes(country))
      } else if (excludeCountry.length) {
        recruiters = recruiters.filter(r => !excludeCountry.includes((r.country || {}).id))
      }
      if (countryExpertise.length) {
        recruiters = recruiters.filter(r => r.local_insights.map(i => i.id).includes(countryExpertise))
      }
      // End of country filter
      if (region) {
        recruiters = recruiters.filter(r => r.regions.find(r => r.id === region))
      }
      if (nativeLangugage) {
        recruiters = recruiters = recruiters.filter(r => (r.native_languages.map(i => i.id).includes(nativeLangugage)))
      }
      if (languages) {
        recruiters = recruiters.filter(r => r.languages.map(i => i.id).includes(languages))
      }
      if (status.length) {
        recruiters = recruiters.filter(r => status.includes((r.status || '').toLowerCase()))
      }
      if (excludeRecStatus.length) {
        recruiters = recruiters.filter(r => !excludeRecStatus.includes(r.status))
      }
      if (availability.length) {
        recruiters = recruiters.filter(r => availability.includes((r.availability || '').toLowerCase()))
      }
      return recruiters
    },
  },
  created () {
    this.getRecruiters()
    axios.get('/public/v2/values/roles')
      .then(({ data }) => {
        this.roleOptions = this.mapValues(data, true)
      })
    axios.get('/public/v2/values/industries')
      .then(({ data }) => {
        this.industryOptions = this.mapValues(data, true)
      })
    axios.get('/public/v2/values/regions')
      .then(({ data }) => {
        this.regionOptions = this.mapValues(data, true)
      })
    axios.get('/public/v2/values/countries')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.sort((a, b) => (a.label > b.label) ? 1 : -1)
        this.countryOptions = temp
      })
    axios.get('/admin/v2/projects')
      .then(({ data }) => {
        this.projects = this.mapValues(data, false, 'title')
      })
    axios.get('/public/v2/values/languages')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.sort((a, b) => (a.label > b.label) ? 1 : -1)
        this.languageOptions = temp
      })
  },
  methods: {
    mapValues (data, prepend = false, textField = 'label') {
      let dataArr = data.map(option => {
        option.value = option.id
        option.text = option[textField]
        return option
      })
      if (prepend) {
        dataArr = [
          { text: 'Select One', value: null },
          ...dataArr,
        ]
      }
      return dataArr
    },
    getRecruiters () {
      return axios.get('/admin/v2/recruiters')
        .then(({ data }) => {
          this.recruiters = data
        })
    },
    remove (recruiterId) {
      const confirmed = confirm('U are about to delete a recruiter, sure you want to do it?')
      if (confirmed) {
        axios.delete(`/admin/v2/recruiters/${recruiterId}`)
          .then(() => {
            this.getRecruiters()
              .then(() => alert('Deleted'))
          })
      }
    },
    confirmMatches () {
      axios.post('/admin/v2/matches/create', {
        project: this.project[0],
        recruiters: this.selectedRecruiters,
      })
        .then(({ data }) => {
          alert('Matches made!')
        })
        .catch(() => {
          alert('Match already exists or some other error!')
        })
    },
    vetting (recruiter) {
      recruiter.vetted = !recruiter.vetted
      axios.patch(`/admin/v2/recruiters/${recruiter.id}`, {
        vetted: recruiter.vetted,
      })
    },
    saveRecruiterInfo (recruiter) {
      axios.patch(`/admin/v2/recruiters/${recruiter.id}`, {
        status: recruiter.status,
        availability: recruiter.availability,
      })
    },
  },
}
</script>

<style>

</style>
