var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',{staticClass:"mt-4"},[_c('b-col',[_c('b-btn',{attrs:{"variant":"success"},on:{"click":_vm.confirmMatches}},[_vm._v(" Confirm matches ")])],1)],1),_c('b-row',[_c('b-col',[_c('label',{attrs:{"for":""}},[_vm._v("Project")]),_c('FormTags',{attrs:{"name":"project","limit":1,"button-text":"Select project","options":_vm.projects},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var option = ref.option;
return [_c('b',[_vm._v(_vm._s(option.business.name))]),_vm._v(" - "+_vm._s(option.title)+" ")]}}]),model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}})],1)],1),_vm._v(" Role "),_c('b-form-select',{attrs:{"options":_vm.roleOptions},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}}),_vm._v(" Industry "),_c('b-form-select',{attrs:{"options":_vm.industryOptions},model:{value:(_vm.selectedIndustry),callback:function ($$v) {_vm.selectedIndustry=$$v},expression:"selectedIndustry"}}),_vm._v(" Regions "),_c('b-form-select',{attrs:{"options":_vm.regionOptions},model:{value:(_vm.selectedRegion),callback:function ($$v) {_vm.selectedRegion=$$v},expression:"selectedRegion"}}),_vm._v(" Country "),_c('b-form-input',{model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_vm._v(" Country expertise "),_c('b-form-select',{attrs:{"options":_vm.countryOptions},model:{value:(_vm.countryExpertise),callback:function ($$v) {_vm.countryExpertise=$$v},expression:"countryExpertise"}}),_c('b-row',[_c('b-col',[_c('label',{attrs:{"for":""}},[_vm._v("Exclude country")]),_c('FormTags',{attrs:{"name":"exclude_country","button-text":"Select country","options":_vm.countryOptions},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var option = ref.option;
return [_c('b',[_vm._v(_vm._s(option.text))])]}}]),model:{value:(_vm.excludeCountry),callback:function ($$v) {_vm.excludeCountry=$$v},expression:"excludeCountry"}})],1)],1),_vm._v(" Native language "),_c('b-form-select',{attrs:{"options":_vm.languageOptions},model:{value:(_vm.nativeLangugage),callback:function ($$v) {_vm.nativeLangugage=$$v},expression:"nativeLangugage"}}),_vm._v(" Languages "),_c('b-form-select',{attrs:{"options":_vm.languageOptions},model:{value:(_vm.languages),callback:function ($$v) {_vm.languages=$$v},expression:"languages"}}),_c('b-row',[_c('b-col',[_c('label',{attrs:{"for":""}},[_vm._v("Recruiter status")]),_c('FormTags',{attrs:{"name":"recruiter_status","button-text":"Select status","options":_vm.statuses},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var option = ref.option;
return [_c('b',[_vm._v(_vm._s(option.text))])]}}]),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('b-row',[_c('b-col',[_c('label',{attrs:{"for":""}},[_vm._v("Exclude Recruiter status")]),_c('FormTags',{attrs:{"name":"exclude_status","button-text":"Select status","options":_vm.statuses},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var option = ref.option;
return [_c('b',[_vm._v(_vm._s(option.text))])]}}]),model:{value:(_vm.excludeRecStatus),callback:function ($$v) {_vm.excludeRecStatus=$$v},expression:"excludeRecStatus"}})],1)],1),_c('b-row',[_c('b-col',[_c('label',{attrs:{"for":""}},[_vm._v("Recruiter's availability")]),_c('FormTags',{attrs:{"name":"availability","button-text":"Select availability","options":_vm.availabilityOptions},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var option = ref.option;
return [_c('b',[_vm._v(_vm._s(option.text))])]}}]),model:{value:(_vm.availability),callback:function ($$v) {_vm.availability=$$v},expression:"availability"}})],1)],1),_c('span',{staticClass:"ml-2 mt-2"},[_c('b',[_vm._v("Counter:")]),_vm._v(" "+_vm._s(_vm.filteredRecruiters.length)+" ")]),_c('b-form-checkbox-group',{model:{value:(_vm.selectedRecruiters),callback:function ($$v) {_vm.selectedRecruiters=$$v},expression:"selectedRecruiters"}},_vm._l((_vm.filteredRecruiters),function(recruiter){return _c('b-card',{key:recruiter.id,staticClass:"mb-4"},[_c('b-form-checkbox',{attrs:{"value":recruiter.id}},[_vm._v(" Select for match ")]),_c('ProfileCard',{attrs:{"match":{
          recruiter: recruiter,
          project: {}
        }}}),_c('div',[_c('b-btn',{staticClass:"mr-5",attrs:{"variant":"success","to":("/admin/recruiter/" + (recruiter.id) + "/edit")}},[_vm._v(" Edit ")]),_c('span',{staticClass:"ml-2"},[_c('b-btn',{staticClass:"mr-2",on:{"click":function($event){return _vm.vetting(recruiter)}}},[_vm._v(" Vetting ")]),_c('b',[_vm._v("Rec status:")]),_c('b-form-select',{staticStyle:{"width":"fit-content"},attrs:{"options":_vm.recruiterStatuses,"size":"sm"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":recruiter.status}},[_vm._v(" "+_vm._s(recruiter.status)+" ")])]},proxy:true}],null,true),model:{value:(recruiter.status),callback:function ($$v) {_vm.$set(recruiter, "status", $$v)},expression:"recruiter.status"}}),_vm._v(" | "),_c('b',[_vm._v("Rec availability:")]),_vm._v(" "+_vm._s(recruiter.availability)+" "),_c('b-btn',{staticClass:"ml-1 p-0",attrs:{"variant":"admin-primary"},on:{"click":function($event){return _vm.saveRecruiterInfo(recruiter)}}},[_vm._v(" Save ")])],1),_c('b-btn',{staticClass:"float-right",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.remove(recruiter.id)}}},[_vm._v(" Delete ")]),_c('p',[_vm._v(_vm._s(recruiter.user.email))])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }